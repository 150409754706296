/* Resets */
body {
  margin: 0;
}

/* Custom css variables can go here */
.radix-themes {
  --brand-green: #aab917;
  --brand-yellow: #f4bc22;

  --slate-200: #e2e8f0;
  --slate-500: #5E6E86;
  --slate-700: #334155;
  --slate-900: #0f172a;

  --white: #ffffff;

  --rag-red: #e11d48;
  --rag-amber: #e07800;
  --rag-light-green: #16a34a;
  --rag-dark-green: #166534;
  --rag-sky: #0284c7;
  --rag-dull: #8496AE;
  --rag-dark-dull: var(--slate-700);
  --rag-purple: #6b21a8;

  --bg-default: #f1f5f9;
  --bg-inputs: #f8fafc;
  --bg-dropdown: #ffffff;

  --font-primary-color: var(--slate-700);
  --font-secondary-color: var(--slate-500);
  --font-link-color: #0284c7;
  --font-negative-color: var(--white);

  --risk-red-bg: #ffe4e6;
  --risk-red-font: #be123c;
  --risk-amber-bg: #fef3c7;
  --risk-amber-font: #b45309;
  --risk-green-bg: #dcfce7;
  --risk-green-font: #15803d;

  --box-shadow-color: #aeaeae40;
  --input-border-color: #7d8ea6;

  --red-a3: #fef2f2;
  --red-a4: #ffe4e6;
  --red-a5: #be123c;
  --red-a6: #dc2626;
  --red-a11: #991b1b;
  --amber-a3: #fff7ed;
  --amber-a4: #fef3c7;
  --amber-a8: #b45309;
  --green-a3: #f0fdf4;
  --green-a4: #dcfce7;
  --green-a6: #65a30d;
  --green-a10: #15803d;
  --green-a11: #166534;
  --lime-a1: #ecfccb;
  --lime-a2: #d9f99d;
  --lime-a3: #bef264;
  --lime-a7: #4d7c0f;
  --lime-a9: #365314;

  --default-font-family: 'Lato', -apple-system, BlinkMacSystemFont, 'Segoe UI (Custom)', Roboto,
    'Helvetica Neue', 'Open Sans (Custom)', system-ui, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji';

  color: var(--font-primary-color);
}
